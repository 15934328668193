const feedbackStrings = {
  title: ["FEEDBACK", "Tell Us What You Think"],
  message: `<p>Thanks for taking the time to answer a few short questions. Your feedback is extremely important to us, and many of ABCya's features are a direct result of comments we have received through this form. We appreciate your help!</p><p>All fields in this form are optional and if you need technical assistance, please contact <a href="/support">ABCya Support</a>.</p>`,
  choose: "CHOOSE YOUR ROLE",
  contact: "Enter Your Contact Information",
  terms:
    'By clicking SUBMIT, you certify that you have read and agreed to our <a href="/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>.',
  submitted: {
    message: "We appreciate your comments and suggestions!",
  },
  questions: {
    title: "Leave Your Comments and Suggestions",
    data: {
      all: [
        "How did you find out about ABCya? What were you looking for at the time?",
        "Do you currently use any other educational websites or apps? Which ones?",
        "What do you like the most about ABCya? Why?",
        "What subjects/lessons would you like to see ABCya add?",
        "Do you have any additional comments or questions?",
        {
          text: "May we share your comments with other members of the ABCya community?",
          answers: [
            { value: "Yes, you may share my comments with my name." },
            { value: "Yes, you may share my comments, but please don't use my name." },
            { value: "No, please don't share my comments.", checked: true },
          ],
        },
      ],
      parent: [
        "Tell us about yourself or your family.",
        "How does your family use ABCya?",
        "How has ABCya helped your children become more successful learners?",
        "What are your biggest challenges as a parent?",
      ],
      teacher: [
        "Tell us about yourself and your students. What grade levels, subjects do you teach?",
        "How do you use ABCya with your students? How has ABCya affected the way you teach?",
        "How has ABCya helped your students become more successful learners?",
        "What are your biggest challenges as a teacher?",
      ],
    },
  },
};

export default feedbackStrings;
