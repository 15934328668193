const strings = {
  abcya: ["ABCya", "ABCya!"],
  about: {
    title: ["ABOUT ABCya", "About ABCya", "ABOUT ABCYA"],
  },
  adblock: {
    content: "Can't see the ^? Try turning off adblocker.",
    modal: {
      title: "Please Turn Off Your Adblocker!",
      message:
        '<p>We at ABCya rely on ads so that we can provide free games to millions of users. To continue playing ABCya games, please turn off your adblocker or sign up for a Premium subscription to remove ads.</p><p><strong>Already a subscriber? <a href="/login/">Log In</a></strong></p>',
    },
  },
  ads: {
    ad: "Advertisement | ",
    go: "Go Ad-Free!",
  },
  ageGate: {
    message: "Enter your date of birth to continue",
    title: "Grown-ups",
    select: {
      year: "YYYY",
      day: "DD",
      month: "MM",
    },
  },
  all: ["ALL", "All"],
  applied: "APPLIED",
  apply: "APPLY",
  appStores: {
    AMAZON: "Amazon App Store",
    ANDROID: "Google Play Store",
    IOS: "Apple App Store",
  },
  attention: ["Attention"],
  back: ["BACK"],
  blog: {
    bodyError: "There was an error retrieving the body content for this blog post.  Please check back soon.",
    featured: "Featured Blog Post",
    go: "View blog posts",
    posted: "Posted on ^",
    recentTitle: "Recent Entries",
    search: "Search Blog Posts",
    title: ["ABCya BLOG", "BLOG", "BLOG POSTS", "blog post", "Blog"],
  },
  browse: ["browse"],
  cancel: ["cancel", "Cancel", "Canceling", "Canceled", "CANCEL"],
  categoryGames: {
    title: "Games Category",
  },
  checkPass: "Checking Password",
  choose: ["CHOOSE"],
  classroom: "Classroom",
  contact: ["Contact Us", "CONTACT US"],
  continue: ["CONTINUE"],
  cookieBanner: {
    title: "ABCya Privacy and Cookies",
    message:
      'ABCya uses cookies in order to offer the best experience of our website. Please review our <a href="/privacy">Privacy Policy</a> for more information.',
    shortname: "make-a-cookie",
  },
  copyright: "Copyright ^ ABCya.com, L.L.C., a division of IXL Learning • All Rights Reserved",
  cost: ["Cost"],
  currencySymbol: {
    usd: "$",
  },
  delete: ["Deleting", "Deleted"],
  dismiss: ["DISMISS"],
  done: ["DONE"],
  education: {
    title: "Education.com",
  },
  educatorInfo: "Educator Info",
  educatorInfoErrorMessage: "Educator info data fetch error: ^",
  emailPreferences: {
    form: {
      subscribe: "Game recommendations",
      unsubscribe: "Unsubscribe from all emails",
    },
    instructions: "Choose which emails you would like to receive from ABCya:",
    manage: {
      button: "Email Preferences",
    },
    notFound: `Your email preferences have not been set up yet. Please check back later or contact support at <a href="mailto:support@abcya.com" rel="noopener noreferrer" target="_blank">support@abcya.com</a>.`,
    success: {
      title: {
        subscribed: "Subscribe Successful",
        unsubscribed: "Unsubscribe Successful",
      },
      message: {
        subscribed: "Success: Your email preferences have been saved.",
        unsubscribed: "You have been successfully unsubscribed from our emails.",
      },
    },
    title: ["EMAIL PREFERENCES", "Email Preferences"],
  },
  emmersion: {
    title: "Emmersion",
  },
  error: ["ERROR"],
  expire: ["expire"],
  externalServiceDown:
    "We are experiencing technical difficulties at the moment, and some features, such as logging in and signing up, may not work. We apologize for the inconvenience, and are working on restoring full functionality.",
  family: "Family",
  faq: {
    feedback: {
      cta: "We love getting feedback from our community! Please be descriptive; the more info you provide, the more we can help.",
      error: {
        message: ["Sorry! Your message has not been sent."],
      },
      game: "Having trouble with a specific game?",
      question: "Didn't ﬁnd an answer? Contact us.",
      success: {
        message: [
          "Thanks! Your message has been sent. We'll be in touch soon.",
          "Thanks! Your message has been sent. We'll be in touch at the email address associated with your ABCya account.",
        ],
        title: "Message Sent",
      },
      terms:
        'By clicking SEND, you certify that you have read and agreed to our <a href="/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>.',
      title: ["Message", "Comments & Questions"],
    },
    title: ["HELP / FAQs", "FAQs"],
  },
  first: ["FIRST"],
  footer: {
    affiliate: "Affiliates",
    content: "OUR CONTENT",
    featured: {
      title: "Featured in",
      outlets: ["The New York Times", "Apple", "USA Today", "Parents", "Scholastic", "Fox News"],
    },
    games: ["^ Games", "Grade ^ Games"],
    kidsafe: {
      alt: "ABCya.com is certified by the kidSAFE Seal Program.",
    },
    brands: {
      ixl: "Comprehensive K-12 personalized learning",
      rosetta: "Immersive learning for 25 languages",
      vocabulary: "Adaptive learning for English vocabulary",
      education: "35,000 worksheets, games, and lesson plans",
      wyzant: "Trusted tutors for 300+ subjects",
      spanishIngles: "Spanish-English dictionary, translator, and learning",
      emmersion: "Fast and accurate language certification",
      tpt: "Marketplace for millions of educator-created resources",
    },
    languageMenu: {
      la: "Navegar en español",
    },
  },
  forgot: {
    caption: [
      "To reset your password, enter the email address for your&nbsp;account.",
      "<p>Reset instructions have been emailed to you.</p><p><strong>If you cannot locate the email in your inbox, please check your junk/spam folder.</strong></p>",
    ],
    loading: "Submitting Reset",
    metaTitle: "Forgot Password",
    subtitle: "Send Reset Email",
    title: "FORGOT PASSWORD",
  },
  forms: {
    accessCode: "Access Code",
    accessName: "Access Name",
    answer: "Answer",
    city: ["City"],
    code: ["Code", "Temporary Code"],
    country: ["Country"],
    currentPassword: "Current Password",
    deviceCount: ["Number of Devices", "Number of devices"],
    email: ["Email Address", "Email Address / Access Name", "Email address", "Email", "Adult's Email Address"],
    emailVerify: "Confirm Email",
    adultEmailVerify: "Confirm Adult's Email",
    feedback: "Feedback",
    getAQuote: "GET A QUOTE",
    name: ["Name"],
    newPassword: ["New Password", "New passwords"],
    newPasswordVerify: "New Password Verify",
    password: ["Password", "Password / Access Code", "Create Password"],
    passwordVerify: "Confirm Password",
    phone: ["Phone Number", "Phone number"],
    position: ["Position"],
    schoolAddress: ["School Address", "School address"],
    schoolName: ["School Name", "School name"],
    select: ["Select..."],
    state: ["State"],
    studentCount: ["Number of Students", "Number of students"],
    yourContact: "Your Contact Information",
    yourDistrictSchool: "Your School/District",
    errorMessages: {
      email: ["^ must contain a valid email address."],
      mustMatch: ["^ must match."],
      isRequired: ["^ is required."],
      isTooLong: "^ must have at most ^ characters.",
      isTooShort: "^ must have at least ^ characters.",
    },
  },
  free: ["Free", "FREE"],
  freeMobileGames: {
    title: "Free Games of the Week",
  },
  game: {
    favorites: {
      add: "Add Favorite",
      adding: "Adding to Favorites",
      mine: "My Favorite!",
      publicLimit: [
        "Want to add more favorite games? Upgrade to a premium account today!",
        "Want to add more favorite games?",
      ],
      remove: "Remove Favorite",
      removing: "Removing from Favorites",
      signup: "Sign up for a premium plan to add unlimited favorites to your account, or up to 3 with a free plan!",
    },
    fullscreen: {
      incompatible: {
        message: "The browser you are using doesn't support fullscreen mode. Please try a different browser.",
        title: "Incompatible Browser",
      },
      signup: "Sign up for a premium plan to play your favorite games in Fullscreen mode",
      title: ["Fullscreen"],
      upgrade: "Upgrade to a premium plan to play your favorite games in Fullscreen mode",
    },
    go: "Go to All Games page",
    mobile: {
      exit: "Exit?",
      find: "Find mobile games",
      notAvailable: "Sorry, this game isn't available on mobile&nbsp;yet.",
      play: "PLAY ALL OUR GAMES ON DESKTOP ANYTIME!",
      signup: "Hello! Sign up for a premium plan to access all of our games on mobile devices.",
    },
    publicUpgrade: "Free Subscriber? Upgrade here",
    similar: "SIMILAR GAMES",
    subscriberLogin: "Premium Subscriber? Log in here",
    title: ["game", "Game", "GAME"],
    trouble: "Trouble Playing This Game?",
    educatorInfo: {
      skills: "Skills Practiced",
      recommended: "Recommended For:",
      info: "Game Info",
      warmUp: "Suggested Warm-Up",
      followUp: "Suggested Follow-Up",
    },
  },
  games: {
    all: ["ALL GAMES", "Visit All Games"],
    title: ["Games", "GAMES"],
    by: "Games by ^",
  },
  giftcard: {
    another: "apply another gift card",
    applying: "Applying Gift Card",
    checking: "Checking Gift Card",
    choose: {
      amount: {
        enter: "Enter Amount",
        rules: "Minimum amount is $10. Maximum is $500. Must be in increments of $5.",
        title: "Choose Amount:",
      },
      design: "Choose Gift Card Design",
    },
    claim: {
      example: "XXXXXXXXXXXXXXXX",
      title: "Claim Code:",
    },
    complete: "COMPLETE PURCHASE",
    confirm: {
      message: "Gift Cards Purchase",
      recipients: "Recipients",
      design: "Design",
    },
    details: "Gift Card Details",
    disclaimer: "Gift cards are nonrefundable.",
    emailError: "Gift card ^ email",
    errors: {
      applied: "The gift card code supplied has already been applied",
      invalid: "The gift card code supplied is invalid",
      max: "The amount for the gift card supplied would exceed the maximum redemption amount of $^",
      redeemed: "The gift card code supplied has already been redeemed.",
    },
    from: {
      email: ["Your Email", "email"],
      name: ["Your Name"],
      title: "From:",
    },
    message: "Message:",
    messageError: "Gift card ^ message",
    optional: "(Optional)",
    preview: "PREVIEW CARD(S)",
    purchase: ["PURCHASE GIFT CARDS", "PURCHASE CARD(S)", "Purchase Gift Cards"],
    quantity: "Quantity",
    recipientEmail: "Recipient Email:",
    redeem: ["REDEEM GIFT CARDS", "Redeem Gift Cards", "REDEEM"],
    redeemed: ["Your gift card codes have been redeemed."],
    subtitle: "ABCya Gift Card",
    success: {
      message: {
        contact: 'Please feel free to <a href="/support">contact us</a> if you have any questions.',
        receipt: "An email receipt with the gift card(s) will be sent to ^.",
        recipients: "The $^ gift card(s) will also be sent to ^.",
      },
      title: "Thank you for purchasing an ABCya Gift Card!",
    },
    title: ["Give the Gift of ABCya!", "GIFT CARD", "Gift Card"],
    total: ["Gift cards applied total: ^", "Gift Card(s) Total:"],
  },
  grade: ["GRADE", "grade", "Grade", "Grades"],
  grades: {
    categories: {
      holiday: "^ HOLIDAY GAMES",
      letters: "^ WORD GAMES",
      numbers: "^ MATH GAMES",
      skill: "^ SKILL GAMES",
      strategy: "^ STRATEGY GAMES",
    },
    sorry: "I'm sorry, there are no games for this grade yet.  Please check back soon!",
    standards: "Grade ^ Common Core Standards",
    title: "GRADE ^",
    featured: "featured games",
    minibanner: "Grades ^ &ndash; ^",
    pre: "pre-",
    standardsCta: "For a full list of aligned Common Core standards, visit our Common Core page:",
  },
  head: {
    defaultTitle: "ABCya! • Learning Games and Apps for Kids",
    description:
      "Educational games for grades PreK through 6 that will keep kids engaged and having fun. Topics include math, reading, typing, just-for-fun logic games… and more!",
    keywords:
      "kids, educational, games, apps, elementary, primary, math, spelling, reading, letters, numbers, practice",
    schemaTitle: "ABCya! • ^",
    titleTemplate: "%s • ABCya!",
  },
  help: {
    title: ["HELP", "Help"],
  },
  gameCategoryGroups: {
    genre: "Genre",
    subject: "Subject",
    theme: "Theme",
  },
  internetExplorer: "ABCya no longer supports Internet Explorer. Please use another browser.",
  ixl: {
    title: "IXL",
  },
  join: [
    "JOIN NOW for Premium",
    "Join Now for Premium",
    "JOIN NOW",
    "JOIN NOW!",
    "JOIN NOW For Premium",
    "JOIN NOW for PREMIUM",
    "JOIN&nbsp;NOW<br />For&nbsp;Premium",
    "Join&nbsp;Now for&nbsp;Premium",
    "Join Now",
  ],
  last: ["LAST"],
  leaving: {
    message: "You are now leaving ABCya.com.",
    cta: "Do you want to continue?",
    disclaimer:
      "By accessing this link you will be leaving our website and entering a website hosted by another party. Please note that you will no longer be subject to, or under the protection of, our privacy and security policies. ABCya is not responsible for the information, content, or product(s) found on third-party websites.",
  },
  loading: "loading...",
  login: {
    caption: "This log-in is for all subscribers.",
    forgot: "Forgot password",
    invalid: {
      title: "You're Logged Out!",
      description: "You have been logged out. Please log in again to keep playing games.",
    },
    legacy: "Redeem old ABCya Games subscription",
    loading: "Logging In",
    notSubscriber: "Not a subscriber?",
    outConfirm: "Are you sure you would like to log out of ABCya.com?",
    title: "Account Access",
    premium: {
      required: "A Premium Subscription is required to access this content.  Please Log In or Join Now to continue.",
    },
  },
  manage: {
    accountInfo: {
      current: "current:",
      student: {
        autoURL: "auto log in url:",
      },
      success: "Your account information has been updated.",
      title: ["CHANGE ACCOUNT INFO", "Change Account Info"],
      ixlSchoolContactCta:
        "To change your account information, please <a href='mailto:sales@abcya.com'>contact the sales team.</a>",
    },
    cancel: {
      canceled: "Your premium subscription has already been canceled and it will become a free plan on ^.",
      password: "If you would like to cancel your subscription please enter your current password.",
      success: "Your ^ has been canceled and will become a free plan on ^.",
      title: ["CANCEL SUBSCRIPTION", "Cancel Subscription"],
      warning: "Your paid account will be canceled at the end of your current billing period, which is on ^.",
    },
    close: {
      title: ["DELETE ACCOUNT"],
    },
    delete: {
      password: "If you would still like to permanently delete your account please enter your current password.",
      success: "Your account has been deleted.  You will no longer be able to log in.",
      title: ["DELETE ACCOUNT", "Delete Account"],
      warning:
        "Deleting your account will permanently delete your information from our database and you will no longer be able to log in effective immediately.",
    },
    password: "Please enter your password to make requested updates.",
    payment: {
      credit: "Update Credit Card",
      success: "Your payment information has been updated.",
      title: ["UPDATE PAYMENT INFO", "Update Payment Info"],
    },
    subscription: {
      balance: "Account balance: ^",
      messages: {
        free: "You are currently enrolled as a Free Subscriber.",
        invoiced: [
          `For assistance or more information about your account, please email us at <a href="mailto:support@abcya.com" rel="noopener noreferrer" target="_blank">support@abcya.com</a>. We're happy to help!`,
        ],
        ixl: "Your ABCya subscription is part of an IXL membership. To update or cancel your subscription, please visit:",
        ixlSchool:
          "Your ABCya subscription is part of an IXL membership. To update or cancel your subscription, please <a href='mailto:sales@abcya.com'>contact the sales team.</a>",
        legacy:
          "Please note that by changing/updating your subscription, you will opt out of the current price of ^ every ^.",
        native: ["To update or cancel your subscription, please visit:"],
        web: ["You must have a credit card on file to change your subscription plan."],
      },
      renew: "Your ^ will ^ on ^",
      success: {
        free: {
          instructions:
            "You will now be logged out and returned to our homepage.  Please log back in to start enjoying your premium plan benefits!",
          upgraded: "Your free plan has been upgraded to a ^!",
        },
        generic: "Your subscription has been updated.",
        premium: "Your ^ has been updated.",
      },
      title: ["MANAGE SUBSCRIPTION", "Manage Subscription"],
    },
    title: ["MANAGE ACCOUNT", "Manage Account"],
    update: {
      prompt: "Please enter your current password.",
    },
  },
  mobileAppDownload: {
    title: "ABCya is better in the app!",
    noAds: "Play games with no ads",
    download: "Download games for offline play",
    smooth: "Enjoy smoother gameplay",
    continue: {
      app: "CONTINUE IN THE APP",
      browser: "Continue in browser",
    },
  },
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  navigation: {
    close: ["CLOSE"],
    download: ["Download"],
    fullscreen: {
      exit: "Exit fullscreen",
      go: "Go fullscreen",
    },
    login: ["Login", "LOG IN", "Log&nbsp;In", "Log In"],
    logout: ["Logout", "LOG OUT", "Log Out", "Log&nbsp;Out"],
    menu: "Menu",
    print: ["Print", "PRINT"],
    search: "Search",
    open: ["Open"],
    more: ["Read More >", "Show More"],
    top: ["Back to Top"],
  },
  new: ["NEW!"],
  next: ["NEXT"],
  no: ["No"],
  none: ["NONE"],
  notApplied: {
    giftCard: {
      cta: 'We see that you entered a gift card code but did not click "APPLY".',
      header: "Gift Card Code Not Applied",
      instructions: 'Please click the cancel button below and then click on "APPLY" next to your gift card code.',
    },
    promo: {
      cta: 'We see that you entered a promo code but did not click "APPLY".',
      header: "Promo Code Not Applied",
      instructions: 'Please click the cancel button below and then click on "APPLY" next to your promo code.',
    },
  },
  notfound: {
    gohome: "Go to home page",
    sorry: "I'm sorry, that ^ can't be found.",
    title: "Not Found",
    item: "content",
  },
  off: ["OFF"],
  ok: "OK",
  on: ["ON"],
  onboarding: {
    welcome: "WELCOME!",
    userType: {
      ads: "Remove advertisements completely",
      notNow: "Not now",
      play: "Play unlimited games online and offline",
      subtitle: {
        family: "ABCya makes learning fun for your whole family.",
        teacher: "ABCya makes learning fun for your whole classroom.",
      },
      unlock: {
        amount: {
          family: "5",
          teacher: "30",
        },
        copy: "Unlock subscriber access for up to ^ devices",
      },
    },
    using: {
      subtitle: "Who is using ABCya?",
      teacher: "Teacher",
      parent: "Parent",
      student: "Student",
    },
  },
  parent: ["PARENT"],
  parentsTeachers: {
    recentBlogs: "Recent Blog Entries",
    title: ["PARENTS & TEACHERS", "Parents & Teachers", "Parents&nbsp;& Teachers"],
    visit: "Visit the Blog",
  },
  password: {
    caption: [
      "Enter a new password and verify your new password to update your account.",
      "Your password was successfully updated.",
    ],
    codecta: "Copy and paste the temporary code from the email you received.",
    loading: "Updating Password",
    metaTitle: "Update Password",
    title: "UPDATE PASSWORD",
  },
  payterms: {
    title: ["PAYMENT TERMS", "Payment Terms"],
  },
  plan: ["Plan"],
  playInLocale: ["JUGAR EN ESPAÑOL", "Jugar en español"],
  print: {
    alt: "ABCya printable image",
    cleanMessage: {
      message: "After printing, you may need to click on the art to return to the content.",
    },
    defaultFile: "ABCya_printFile",
    pdfMessage: {
      message: {
        download:
          "Your browser doesn't support PDF printing. Click the DOWNLOAD button to download the PDF file. You can then print the file directly from your PDF reader.",
        open: "Your browser doesn't support PDF printing. Click the OPEN button to open the PDF file in a separate window or tab. You can then print or save the file directly from the PDF reader.",
      },
    },
    saveMessage: {
      message:
        "Your browser doesn't support downloading from web pages. Click the OPEN button to open the file in a separate window or tab. You can then save or print the file directly from the browser.",
    },
    error: {
      sorry: "We're Sorry!",
      message: "An error occurred while printing or saving.",
    },
  },
  printables: {
    title: ["printables", "printables category", "printable"],
    go: "Go to Printables page",
    similar: {
      games: "RELATED GAMES",
    },
    meta: {
      title: "Printables",
      description: "Printable coloring pages, activities, worksheets and teacher resources by ABCya!",
      keywords: "printable, teacher resources, coloring pages, activities, worksheets",
    },
  },
  privacy: {
    title: ["PRIVACY POLICY", "Privacy Policy"],
  },
  privacymobile: {
    title: ["MOBILE APP PRIVACY POLICY", "Mobile App Privacy Policy"],
  },
  promo: {
    title: ["PROMO"],
    code: ["Promo Code"],
    applying: "Applying Promo Code",
    applied: "Promo Applied",
    invalid: "Promo is either invalid or has ended",
    off: "^ OFF!",
  },
  purchasing: "Purchasing",
  recaptcha: `This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" rel="noopener noreferrer" target="_blank">Terms of Service</a> apply.`,
  redeem: {
    card: {
      anotherEmail: "TRY A DIFFERENT EMAIL ADDRESS",
      cta: "Please enter your email address:",
      free: {
        disclaim: "<p>Gift cards can be redeemed during the upgrade account process.</p>",
        message: {
          isLoggedIn:
            "<p>In order to redeem your gift card, kindly go to manage account and then upgrade to a premium account.</p>",
          loggedInDiff: `<p>In order to redeem your gift card, kindly log out of ^’s account, and log back in with ^.</p><p>Once logged in, go to Manage Account and upgrade to a premium account.</p>`,
          loggedOut:
            "<p>In order to redeem your gift card, kindly log in to ABCya.com with ^ and then upgrade to a premium account.</p>",
        },
        title: "Looks like you currently have a free account.",
      },
      friends: {
        message: '<p>Please <a href="/support">contact customer support</a>.</p>',
        title: "Looks like you have a friends and family subscription.",
      },
      invoiced: {
        message: '<p>Please <a href="/support">contact customer support</a> to redeem your gift card.</p>',
        title: "Looks like you have an invoiced subscription.",
      },
      native: {
        message: `<p>Currently only subscribers to the ABCya.com website can redeem ABCya gift cards.</p>
          <p>Here’s how to redeem your gift card
          <ol><li><a href="/faq/#apps" rel="noopener noreferrer" target="_blank">Cancel your ABCya Games mobile app subscription</a></li>
          <li>Once your subscription through the ^ expires, kindly login to ABCya.com from your desktop computer, using the same credentials you used to login to the ABCya Games app</li>
          <li>Once you are logged in to ABCya.com, sign up for a premium account. Gift cards can be redeemed during the ABCya.com upgrade account process.</li></ol></p>`,
        title: "Looks like you currently subscribe to the ABCya Games app through the ^.",
      },
      notFound: {
        message:
          "<p>^ does not have an ABCya account yet.</p><p>Create a premium account so you can redeem your gift card!</p><p>Gift cards can be redeemed during the account signup process.</p>",
        title: "We don't recognize that email address.",
      },
      redeemed: {
        cta: "REDEEM ANOTHER",
        message: "^ has been added to the account for ^.",
        title: "Giftcard redeemed!",
      },
      subtitle: "Let's add this credit to your account!",
      title: "We Found Your Card!",
    },
    code: ["Gift Card Code", "Enter Your Code:"],
    title: ["Redeem Your ABCya Gift Card!"],
  },
  renew: ["renew"],
  rosetta: {
    title: "Rosetta Stone",
  },
  save: {
    defaultFile: "ABCya_saveFile",
  },
  search: {
    faq: {
      answer: "Answer:",
      question: "^ question:",
    },
    filter: "Filter Results:",
    noresults: "Sorry, no search results",
    resultCounts: "^ Results (Page ^ of ^)",
    title: ["SEARCH RESULTS FOR: ^"],
    cta: "Search ^",
  },
  searchResults: ["Search Results"],
  send: ["SEND", "Sending"],
  spanishIngles: {
    title: "SpanishDictionary.com",
  },
  standards: {
    games: "Common Core Standards Sorted by Games",
    printables: "Common Core Standards Sorted by Printables",
    noresults: [
      "This game is not currently aligned to any Common Core standards",
      "Currently we don't have any games that meet or are assigned to this standard.",
    ],
    page: ["COMMON CORE STANDARDS PAGE", "GO TO GAME PAGE", "GO TO PRINTABLE PAGE"],
    search: "Search Standards",
    sort: "SORT BY:",
    subjects: {
      ELA: "ENGLISH LANGUAGE ARTS",
      MATH: "MATHEMATICS",
      NGSS: "NEXT GENERATION SCIENCE STANDARDS",
    },
    subtitle: ["& NEXT GENERATION SCIENCE STANDARDS"],
    title: [
      "COMMON CORE STANDARDS",
      "Common Core",
      "Common Core Standards",
      "STANDARD",
      "Standards",
      "Common&nbsp;Core Standards",
    ],
  },
  store: ["Store"],
  submit: ["Submit", "SUBMIT", "Submitting"],
  subscribe: {
    accessDesc: "Create an easy username and password so that your students can access the ABCya account.",
    complete: {
      code: "Completion Code",
      codecta: "Copy and paste the completion code from the email you received.",
      emailcta: "Provide address that received the completion email.",
      loading: "Completing Signup",
      thankyou: {
        autologin:
          "<p>The student access auto login url is: <strong>^</strong></p><p>The auto login url can also be found in the manage account page at any time.</p>",
        message: "Your ABCya account is all set up!",
      },
      title: ["Complete Account Signup", "COMPLETE SIGNUP"],
    },
    confirm: {
      title: "PLEASE CONFIRM",
      message: "You are about to subscribe to:",
    },
    create: "Create Account",
    creditNotRequired:
      "Credit card only required if chosen plan amount is more than available account balance or applied gift card total.",
    cta: "Choose a Plan",
    desktopSignup: {
      title: "Unlock the Best of ABCya!",
      email: "Get the Game of the Week in your inbox",
      printable: "Access Premium Printables for free",
      cta: "sign up",
      login: 'Already have an account? <a id="^">Log in</a> here.',
    },
    error: {
      accessNameExists: "It appears that the Access Name you have chosen already exists. Please choose another one.",
      existsOnDifferentPlatform:
        "It appears that you already have an ABCya account, which was purchased ^. Click OK to log in using your existing username or to retrieve your password.",
      existsOnThisPlatform:
        "It appears that you already have an ABCya account. Click OK to log in using your existing username or to retrieve your password.",
      existsOnUnknownPlatform:
        "It appears that you already have an ABCya account, which was purchase on an unknown platform. Click OK to log in using your existing username or to retrieve your password.",
      stripeInputError: "Error verifying credit card information. Please try again or contact customer support.",
    },
    features: {
      columns: [
        "6 Games Each Week",
        "Complete Access",
        "Up to 3 Games",
        "Unlimited",
        "1",
        "Up to 5",
        "Up to 30",
        "/Month*",
      ],
      rows: [
        "Play All Regular Games on Desktop",
        "Access Hundreds of ABCya Printables",
        "Mobile Access to ABCya",
        "Ad-Free Experience",
        "Save Favorite Games",
        "Play Games at Fullscreen Size",
        "Set Timer for ABCya Access",
        "Control Game Category Access",
        "Number of Devices",
        "Price",
      ],
    },
    giftcard: {
      buying: "Buying for someone else?",
    },
    loading: "Joining!",
    options: ["MEMBERSHIP OPTIONS"],
    payment: "Payment Information",
    plans: {
      best: "BEST VALUE!",
      classroom: {
        perks: ["No Ads", "Complete Mobile Access", "Up to 30 Devices"],
        title: [
          "PREMIUM CLASSROOM",
          "PREMIUM CLASSROOM PLANS",
          "Premium Classroom",
          "Premium Classroom Plan",
          "premium classroom plan",
        ],
      },
      family: {
        perks: ["No Ads", "Complete Mobile Access", "Up to 5 Devices"],
        title: [
          "PREMIUM FAMILY",
          "PREMIUM FAMILY PLANS",
          "Premium Family",
          "Premium Family Plan",
          "premium family plan",
        ],
      },
      free: {
        title: ["FREE SUBSCRIBER", "Free Subscriber", "Free Plan", "free plan"],
      },
      m1: {
        disclaimer: ["Billed monthly, cancel anytime"],
        term: "per month",
        renewal: "month",
        title: ["Monthly Subscription", "monthly subscription"],
      },
      m6: {
        disclaimer: ["One payment of ^, recurring every 6 months", "Renews every 6 months, cancel anytime"],
        term: "per half year",
        renewal: "6 months",
        title: ["6-Month Subscription", "6-month subscription"],
      },
      m12: {
        disclaimer: ["One payment of ^, recurring every 12 months", "Renews every 12 months, cancel anytime"],
        term: "per year",
        renewal: "12 months",
        title: ["Annual Subscription", "annual subscription"],
      },
      promo: {
        disclaimer: ["Only ^ per month!"],
      },
      school: {
        back: "PLAN SELECTION",
        cta: "GET ABCya FOR YOUR SCHOOL!",
        perks: [
          "<strong>Ad-free and mobile ABCya access</strong> on any number of devices",
          "<strong>Hundreds of exclusive printables</strong> and extra features",
          "<strong>Joint subscription with IXL</strong> for maximum educational value",
        ],
        perksStripe: ["No ads", "Complete Mobile Access", "Unlimited Devices"],
        purchase: "PURCHASE SCHOOL SUBSCRIPTION",
        learnMore: "Fill out the form below to learn more!",
        looking: "Looking for Family or Classroom plans?",
        title: ["PREMIUM SCHOOL PLAN", "Premium School", "Premium School Plan", "premium school plan"],
        want: "Want to get ABCya for your whole school?",
        requestQuote: "Request a Quote",
        renewal: "12 months",
      },
    },
    purchasedFrom: {
      amazon: "in the Amazon store",
      android: "in Google Play",
      ios: "in the iOS App Store",
      stripe: "at abcya.com",
    },
    school: {
      check: 'If you would prefer to pay by check or Purchase Order, please <a href="/support">Contact Us</a>.',
      thankyou: "Thank you for your interest in ABCya's premium school plan!",
      respond: "We've received your information and someone will respond to you within one business day.",
      home: "HOME",
    },
    student: "Student Access Information",
    submit: "SUBSCRIBE",
    terms: `By clicking ^, you certify that you have read and agreed to our <a href="/terms" rel="noopener noreferrer" target="_blank">Terms of Use</a>, <a href="/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>, and <a href="/payterms" rel="noopener noreferrer" target="_blank">Payment Terms</a>.`,
    thankyou: {
      message:
        "You are now subscribed. You will soon be receiving an email with a link to verify the email address you used for your account.",
      submit: "START PLAYING GAMES!",
      title: "Thank you for joining ABCya!",
    },
    title: ["Subscribe"],
  },
  subscription: ["subscription"],
  subtotal: ["Subtotal"],
  support: {
    title: "SUPPORT",
  },
  tagline: "The Leader in Educational Games for Kids!",
  taglineIxl: "Making educational experiences better for everyone.",
  teacher: ["TEACHER"],
  terms: {
    title: ["TERMS AND CONDITIONS", "Terms of Use", "TERMS OF USE"],
  },
  thankyou: ["THANK YOU!", "Thank You!"],
  tools: {
    category: {
      instructions: "Click categories to toggle them On or Off.",
      title: ["CATEGORY CONTROL", "Category Control"],
    },
    favorite: {
      instructions: "To add more Favorite Games,",
      title: ["FAVORITE GAMES", "Favorite Games"],
    },
    timer: {
      alert: ["Time's Up!"],
      hours: "Hours",
      instructions:
        "Set a timer for the amount of time you would like to spend on ABCya. You will receive a pop-up message when your time is done.",
      minutes: "Minutes",
      remaining: "There are ^ hours, ^ minutes and ^ seconds remaining.",
      remainingNoSeconds: "There are ^ hours, ^ minutes remaining.",
      seconds: "Seconds",
      title: ["TIMER", "Timer"],
    },
    title: ["TOOLS", "Tools"],
    upgrade: "For access to this tool,",
  },
  total: ["Total"],
  tpt: {
    title: "TPT",
  },
  update: ["UPDATE", "Updating", "Updated", "Update"],
  upgrade: {
    title: ["UPGRADE to PREMIUM", "Upgrade to Premium", "UPGRADE TO PREMIUM", "Upgrade to Premium!"],
    message: ["A Premium Subscription is required to access this content.  Upgrade to a premium account today!"],
  },
  verify: {
    login: 'Please <a href="/login">login</a> to continue.',
    metaTitle: "Verify Email Address",
    thankyou: "Thank you for confirming your email address.",
    title: "VERIFY EMAIL ADDRESS",
    verifying: "Verifying",
    notVerified: `I'm sorry, there was some trouble verifying your email address.  Please try again or <a href="/support">contact customer support</a>.`,
  },
  vocabulary: {
    title: "Vocabulary.com",
  },
  wyzant: {
    title: "Wyzant",
  },
  yes: ["Yes"],
};

export default strings;
